<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 550"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <font class="">Acknowledgement </font
          ><font v-if="!$vuetify.breakpoint.xsOnly">
            of Country
          </font>
        </span>
      </template>

      <v-card color="dialogToobarColor" class="white--text">
        <v-toolbar
          class="trailSelectorToolBar"
          dark
          color="dialogToobarColor"
          elevation="2"
          :xheight="$vuetify.breakpoint.smAndDown ? 42 : 54"
        >
          <v-spacer v-show="!$vuetify.breakpoint.smAndDown"></v-spacer>
          <v-toolbar-title>
            <font>
              Acknowledgement of Country
            </font>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn dark icon @click="openIntro = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <center class="mx-8 title aoc">
          <v-card-text>
            In the spirit of reconciliation, the Lone Pine Koala Sanctuary
            acknowledges the Traditional Custodians of Country throughout
            Australia and their connections to land, sea, and community. We pay
            our respect to their elders past and present and extend that respect
            to all Aboriginal and Torres Strait Islander peoples today.
          </v-card-text>
        </center>

        <v-card-actions v-if="false">
          <v-spacer></v-spacer>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../../main";

export default {
  name: "AcknowledgementModal",
  components: {},
  data() {
    return {
      dontShowAgain: false,
      openIntro: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  methods: {
    /*
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowAcknowedgement", this.dontShowAgain);
    },
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },
*/
  },
  created() {
    eventBus.$on("loadAocDialog", () => {
      this.openIntro = true;
    });
  },
  beforeDestroy() {
    //do something before destroying vue instance
    eventBus.$off("loadAocDialog");
  },

  mounted() {}
};
</script>

<style>
.title.aoc .v-card__text {
  font-size: 1.05rem;
  font-weight: 300;
  line-height: 1.4rem;
}
</style>
