<template>
  <div class="">
    <v-list-item-title v-if="!site.hasTitleAndTime">
      {{ site.properties.name }}
    </v-list-item-title>

    <v-list-item-title v-if="site.hasTitleAndTime" class="keeperTalkItem">
      <div class="d-flex justify-start">
        <div class="keeperTalkHeading keeperTalkHeadingTime ">
          <div
            class="d-flex justify-space-between   notranslate"
            translate="no"
          >
            <v-icon v-if="false" v-text="'mdi-clock'"></v-icon>
            <div>
              {{ site.time }}
            </div>
          </div>
        </div>

        <div class="keeperTalkHeading keeperTalkHeadingText">
          {{ site.title }}
        </div>
      </div>
    </v-list-item-title>
  </div>
</template>

<script>
//import { mapState } from "vuex";
//import { eventBus } from "../../main";

export default {
  name: "SignageListItemSignageListCustomItem",
  props: {
    site: Object
  },
  computed: {
    categoryItemList() {
      return null;
    }
  },
  data: () => ({
    selectedItem: null
  }),
  methods: {},
  created() {},
  watch: {},

  async mounted() {}
};
</script>
<style>
.keeperTalkHeading {
  display: inline-block;
  min-height: 24px;
  line-height: 23px;
  font-size: 14px;
}


.keeperTalkNonTimeHeadingText {
  
  

  font-size: 14px;
  font-weight: 500;

}
.keeperTalkHeadingText {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 500;

  margin-left: 10px;
}

.keeperTalkHeadingTime {
  width: 100px;
}

@media only screen and (max-width: 600px) {
  .keeperTalkItem {
  }

  .keeperTalkItem > div.d-flex {
    flex-direction: column !important;
  }

  .keeperTalkItem > div > div {
  }

  .keeperTalkHeadingText {
    margin-left: 0;
  }

  .keeperTalkHeadingText {
    max-width: 210px;
  }
}
</style>
