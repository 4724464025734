const siteSwitcherService = require("./../../service/siteSwitcherService.js");

const siteGlobal = siteSwitcherService.getSiteSetup().global;

const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};

const state = {
  siteId: siteSwitcherService.getId(),
  siteObject: siteSwitcherService.getSiteObj(),
  siteSetup: siteSwitcherService.getSiteSetup(),
  maptivateSetupObject: {},
  appSettings: {
    //wpEditSite:"https://cms01.maptivateapp.com.au/lone-pine-koala-sanctuary/wp-admin/post.php?post=",

    map: {
      accessToken:
        "pk.eyJ1IjoibG9uZXBpbmUiLCJhIjoiY20zdzk1M2dnMTRxcjJxb3M2bnp3bTgzeCJ9.z18yvKJ20lv1a4B1M8J89A", //maptivate service;
      options: {
        style: "mapbox://styles/lonepine/cm40vasu100pt01rdbg82aujp", //service account style

        center: [152.9688, -27.5338],
        maxZoom: 24,
        minZoom: 11,
        pitch: 20,
        bearing: 270,
        //bearing: 0,
        zoom: 15.8
      },
      bounds: [
        [152.8813449332, -27.5969480151], // Southwest coordinates
        [153.0619029459, -27.4683523384] // Northeast coordinates
      ],
      defaultSetup: {
        center: [152.9688, -27.5338],
        zoom: 20,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 40,
        bearing: 270
        //bearing: 0
      },
      flyToBounds: [
        [152.9674548469, -27.5376168468],
        [152.9700458609, -27.5331645139]
      ],
      disableRotationAndBearing: false
    },

    layerDefaults: {
      signage: {
        labelField: "mv_label",

        scaling: {
          minZoom: 18,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 0.7
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 1, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        labelField: "mv_label",
        numberLabelField: "SiteMarkerNumberLabelAct",

        useCircle: true,
        scaling: {
          //minZoom: 18.1,
          minZoom: 17.5,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 1
        },

        hasPopup: true,
        showLabels: true,
        hoverState: true,
        haveToolTip: false,
        styles: {
          //unclustered_paint: { "circle-color": "#ff0000" },
          //clustered_paint: { "circle-color": "#354d64" },

          unclustered_icon_size: {
            stops: [
              [18, 7.5 / 35],
              [20, 15 / 35]
            ]
          },
          unclustered_radius: {
            stops: [
              [18, 7.5 / 2],
              [20, 15 / 2]
            ]
          },
          clustered_radius: {
            stops: [
              [18, 15],
              [20, 25]
            ]
          }
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterLabel: true,
        clusterSetup: {
          cluster: true,
          //clusterMaxZoom: 17,//setting this to null will make it work at 18, if i set it to 18 seems to break it.
          clusterRadius: 30,

          clusterProperties: {}
        },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          unclustered_layer_symbol: [
            "all",
            ["!has", "point_count"],
            ["==", "render_icons", true]
          ],
          //unclustered_layer: ["in", "section_slug","points-of-interest"],

          clustered_layer: ["has", "point_count"]
        }
      }
    },
    zoomToOnSelect: 19,

    onMoreInfoLoadOnMobileExpanText: true,

    pointsHaveDirections: false,
    markerIsPointer: false,
    showSiteNumbers: false,
    showSiteNumbers_Labels: true,
    showSiteNumbers_ClusterLabels: false,
    showQrCode: false,
    minZoom: { main: 1, secondary: 1, points: 1 },

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    useMapClustering: true,

    layersUnder: {
      art: null, //"melbzoo-labels (1)",
      places: "labels-zoomed-out", //"melbzoo-labels (1)",
      signage: null, //"melbzoo-labels (1)",
      lines: false,
      fill: false,
      arrows: false
    },
    defaultTooltipLabelField: "mv_tooltip",

    markerImageAssetOrderPriority: {
      //list by section slug name,
      artwork_section: {
        catTaxonomy: "artwork_section",
        name: "artwork",
        behaviour: "normal", //normal, cluster,
        skip: [],
        priority: [
          "artwork_zoom1",
          "animals-hero",
          "buildings-hero",
          "north-arrow"
        ],
        delay: []
      },
      signage_section: {
        catTaxonomy: "signage_section",
        name: "signage",
        behaviour: "normal", //normal, cluster,
        skip: [""],
        priority: [""],
        delay: ["sensory"]
      },
      markersection: {
        catTaxonomy: "markersection",
        name: "marker",
        behaviour: "cluster",
        load: []
      }
    },

    siteListSettings: {
      itemUseCircle: true,
      sectionUseCircle: true
    },

    allowSkipWelcomeDialog: false,

    autoOpenMoreInfo: false,
    drawWidth: 450,
    mobDrawWidth: 300,

    popupButtonWidth: "269px",
    drawButtonWidth: "435px",

    mediaButtonColor: "mediaButtonColor", //a6caa6
    filterLegendButtonColor: "filterLegendButtonColor", //a6caa6

    audioPlayColor: "white",
    headingTextColor: "font-weight-regular text--darken-1",

    mainTabDark: true,
    listFilterTabDark: false,

    langButtonTextDark: true,

    titleImageLogo: true,
    titleImageLogoColor: false,

    styles: {
      markerColours: [],
      //oldmarkerText: "#f6e9d8",
      markerTextLabel: "black",
      markerText: "white",
      markerTextBg: "white"
    },

    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },
    ...siteGlobal.appSettings
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
