<template>
  <div>
    <v-list flat dense class="eventList">
      <v-list-item-group dense v-model="selectedItem" color="primary">
        <v-list-item
          dense
          v-for="(site, i) in menuListItemsAct"
          :key="i"
          :title="site.main"
          @click="focusOnSite(site)"
        >
          <v-list-item-avatar v-if="false"
            dense
            tile
            size="25"
            class="signListAvatar ma-0 mr-2"
          >
            <img
              style=""
              v-if="site.list_image_obj && site.list_image_obj.marker"
              :src="site.list_image_obj.marker"
            />
          </v-list-item-avatar>

          <v-list-item-avatar
            dense
            tile
            size="25"
            class="signListAvatar ma-0 mr-2"
          >
            <img
              style=""
              :src="
                `/assets/logos/${site.payForEvent ? 'ticket' : 'clock'}.png`
              "
            />
          </v-list-item-avatar>

          <v-list-item-content
            dense
            v-if="site.hasTitleAndTime && !site.errorTime"
          >
            <SignageListCustomItem :site="site"> </SignageListCustomItem>
          </v-list-item-content>

          <v-list-item-content
            v-else
            dense
            class="keeperTalkNonTimeHeadingText"
          >
            {{ site.title }} {{ site.time ? " - " + site.time : "" }}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";
const moment = require("moment");

import SignageListCustomItem from "./SignageListCustomItem.vue";

export default {
  name: "sidebarEventsList",
  components: {
    SignageListCustomItem
  },
  computed: {
    ...mapState({
      markerList: state => state.markers.markerList
    }),

    menuListItems() {
      let sites = this.markerList.data.features;

      let menuListItems = sites
        .map(row => row.properties)
        .filter(row => {
          let { list_section_slug } = row;

          if (
            list_section_slug &&
            this?.showSectionSlugs?.length &&
            this.showSectionSlugs.includes(list_section_slug)
          ) {
            return true;
          } else {
            return false;
          }
        })

        .map(properties => {
          let {
            Point_Name,
            post_id,

            slug,
            Longitude,
            Latitude,
            list_image_obj,
            SiteMarkerNumberLabelAct,
            structuredData
          } = properties;

          let hasTitleAndTime = false;

          let timeString = null;
          let payForEvent = false;
          let bookingLinks = null;
          try {
            hasTitleAndTime = true;
            timeString = structuredData?.subtitle?.M_ST_Event_Times?.value;
          } catch (error) {
            //
          }

          try {
            payForEvent =
              structuredData?.value?.M_C_YN_pay_for_event?.value === "Yes"
                ? true
                : false;
          } catch (error) {
            //
          }

          try {
            bookingLinks = structuredData?.links?.M_L_Experience_Booking_Link;
          } catch (error) {
            //
          }

          let main = Point_Name;

          let newRow = {
            main,
            title: Point_Name,
            post_id,

            slug,
            Longitude,
            Latitude,
            list_image_obj,
            SiteMarkerNumberLabelAct,
            bookingLinks,
            payForEvent,
            time: timeString,
            hasTitleAndTime,
            properties
          };

          return newRow;
        });

      return menuListItems;
    },
    menuListItemsAct() {
      let output = [];
      let nonTimeOutput = [];

      this.menuListItems.forEach(item => {
        let { time } = item;
        if (time) {
          let timeArray = time
            .replaceAll("and", " | ")
            .split("|")
            .map(row => row.trim());

          timeArray.forEach(timeItem => {
            let timeInMins;

            let justTime = timeItem;
            try {
              justTime = timeItem.split("-")[0].trim();
            } catch (error) {
              //
            }

            let parsedTime = moment(justTime, "h:mma");

            //timeAct = parsedTime.toDate();
            try {
              timeInMins = parsedTime.hours() * 60 + parsedTime.minutes();
            } catch (error) {
              //console.log("error!!!", error);
            }

            let outputValue = { ...item, timeInMins, parsedTime };

            if (timeItem.includes("-")) {
              timeItem = timeItem.replaceAll(":00", "");
            }

            outputValue.time = timeItem;

            if (!isNaN(timeInMins)) {
              output.push(outputValue);
            } else {
              outputValue.errorTime = true;

              nonTimeOutput.push(outputValue);
            }
          });
        }
      });

      try {
        output.sort((a, b) => a?.timeInMins - b?.timeInMins);
      } catch (error) {
        //do nothing;
      }
      output = [...nonTimeOutput, ...output];

      return output;
    }
  },

  methods: {
    focusOnSite(item) {
      let site = this.markerList.data.features.filter(
        row => row.properties.slug === item.slug
      )[0];

      console.log(site.properties);

      try {
        let section_slug = site?.properties?.section_slug;
        this.$store.dispatch("filters_enableLayer", section_slug);

        try {
          let slug = site?.properties?.slug;

          window.mvAnalyticEvent(
            "ui",
            "signListClick",
            `${section_slug}_${slug}`,
            false
          );
        } catch (error) {
          //do nothing;
        }
      } catch (error) {
        //do nothing;
      }

      setTimeout(() => {
        //if(!this)
        eventBus.$emit("nav_map_flyToOpenPopUp_Event", site?.properties?.index);
      }, 1);

      setTimeout(() => {
        this.selectedItem = null;
      }, 300);
    }
  },

  data: () => ({
    selectedItem: null,
    showSectionSlugs: ["keeper-talks-and-experiences"]
  })
};
</script>
<style scoped>
.v-list--dense.eventList .v-list-item {
  min-height: 25px;
  height: auto;
}

.v-list--dense.eventList .v-list-item .v-list-item__content {
  padding: 4px 0;
}
</style>
