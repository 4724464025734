<template>
  <v-card
    elevation="6"
    x_shaped
    v-if="ready"
    class="pb-0 mvPopUpCard"
    ref="popupTop"
  >
    <v-btn
      elevation="0"
      v-if="properties.media && properties.media.length"
      @click="clearAllPopups"
      class="topRightCloseBox"
      fab
      x-small
      dark
      color="transparentBlackClose"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-btn
      elevation="0"
      v-if="properties.media && properties.media.length"
      @click="loadImageGallery(properties.Site_Marker_Number)"
      class="topLeftMediaButton"
      fab
      x-small
      dark
      color="transparentBlackClose"
    >
      <v-icon>mdi-arrow-expand-all</v-icon>
    </v-btn>

    <minGallery
      :appSettings="appSettings"
      :properties="properties"
      :isPopup="true"
    >
    </minGallery>
    <v-btn
      v-if="!(properties.media && properties.media.length)"
      @click="clearAllPopups"
      class="topRightCloseBox "
      icon
      color="secondary"
      small
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="titleBox  secondary--text mr-2">
      <v-card-title key="" v-if="properties.Point_Name" class="pt-1 ">
        <engAndTrans :value="properties.Point_Name" />
      </v-card-title>

      <v-card-subtitle
        translate="no"
        class="notranslate pb-1"
        v-if="
          properties.structuredData &&
            properties.structuredData.subtitle &&
            properties.structuredData.subtitle.M_ST_Event_Times &&
            properties.structuredData.subtitle.M_ST_Event_Times.value
        "
      >
        <template
          v-if="
            properties.structuredData &&
              properties.structuredData.value &&
              properties.structuredData.value.M_C_YN_pay_for_event &&
              properties.structuredData.value.M_C_YN_pay_for_event.value
          "
        >
          <img
            height="24px"
            style="    margin-bottom: -7px;"
            :src="
              `/assets/logos/${
                properties.structuredData.value.M_C_YN_pay_for_event.value ==
                'Yes'
                  ? 'ticket'
                  : 'clock'
              }.png`
            "
          />
        </template>

        {{ properties.structuredData.subtitle.M_ST_Event_Times.value }}
      </v-card-subtitle>

      <v-card-subtitle
        :title="`First Nations Place Name: ${properties.firstNationsName}`"
        translate="no"
        class="notranslate pb-1"
        v-if="
          properties.firstNationsName && properties.marker_type == 'destination'
        "
      >
        {{ properties.firstNationsName }}
      </v-card-subtitle>
    </div>

    <PointDescriptionBody
      :appSettings="appSettings"
      :functions="{ updateShowMore, openSidebarRemote }"
      type="popup"
      :Point_Description="Point_Description"
      :showMore="showMore"
    />

    <extraInfoBlocks
      :appSettings="appSettings"
      :properties="properties"
      :location="properties"
      :media="properties.media"
      :Point_Name="properties.Point_Name"
      :Site_Marker_Number="properties.Site_Marker_Number"
      :structuredData="properties.structuredData"
      :functions="{ openYoutube, setSound, getAudioDetails, pointSet }"
      :isPopup="true"
    />

    <a
      class="ml-2"
      @click="editWpItem(properties.post_id)"
      v-if="isDevEnviroment && properties.post_id"
    >
      cust wp edit
    </a>

    <v-btn
      elevation="0"
      dark
      class="pb-0 mb-0 pt-0 mt-0 moreInfoButton my-0 pb-0"
      :color="'questGold'"
      @click="openSidebar(properties.index)"
      width="100%"
    >
      More Info
    </v-btn>
    <v-spacer></v-spacer>
  </v-card>
</template>

<script>
import { eventBus } from "../../main";

import Vue from "vue";

import extraInfoBlocks from "../moreInfo/extraInfoBlocks.vue";
import engAndTrans from "./../ui/engAndTrans.vue";
import PointDescriptionBody from "./../ui/PointDescriptionBody.vue";

import minGallery from "./../moreInfo/minGallery.vue";

export default Vue.extend({
  components: {
    extraInfoBlocks,
    engAndTrans,
    PointDescriptionBody,
    minGallery
  },
  data: () => ({
    showMore: false,
    ready: false,
    showCarousel: false,
    media: []
  }),

  props: {
    //goToPointByPointName: Function,
    isDevEnviroment: Boolean,
    editPost: String,

    getAudioDetails: Function,
    openYoutube: Function,
    setSound: Function,
    openSidebar: Function,
    clearAllPopups: Function,
    appSettings: Object,
    pointSet: Object,
    properties: {
      required: true,
      type: Object
    },
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    Point_Description() {
      return this.properties?.content?.rendered;
    },

    linkPoint() {
      var link = null;
      try {
        if (this.properties && this.properties.linkPoint) {
          link = this.properties.linkPoint;

          try {
            link = JSON.parse(link);
          } catch (error) {
            //do nothing;
          }
        }
      } catch (error) {
        //do nothing;
      }
      return link;
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    if (this.browserDetectCopy) {
      this.$browserDetect = this.browserDetectCopy;
    }

    this.ready = true;
    this.showCarousel = true;

    setTimeout(() => {
      if (
        !this.$vuetify.breakpoint.mdAndDown &&
        this.appSettings.autoOpenMoreInfo
      ) {
        //not small!!!
        this.openSidebar(this.properties.index);
      }
    }, 500);
  },

  methods: {
    loadImageGallery(Site_Marker_Number) {
      eventBus.$emit("loadGallery", Site_Marker_Number);
    },
    editWpItem(postid) {
      if (postid && this.isDevEnviroment) {
        window.open(`${this.editPost}${postid}&action=edit`);
      }
    },

    openSidebarRemote(alsoShowMore) {
      this.openSidebar(this.properties.index);

      if (alsoShowMore) {
        eventBus.$emit("pointDiscription_expand");
      }
    },

    updateShowMore(value) {
      this.showMore = value ? value : false;
    }
  }
});
</script>

<style lang="scss">
.mapWrapper.zoomOut .galPopup.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  padding-bottom: 16px;
}

.mapWrapper.zoomMid .galPopup.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  padding-bottom: 30px;
}

.mapWrapper.zoomIn .galPopup.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  padding-bottom: 40px;
}

.v-application .transparentBlackClose.topLeftMediaButton,
.v-application .transparentBlackClose.topRightMediaButton {
  background-color: #00000042 !important;
  border-color: #00000042 !important;
}

.topLeftMediaButton {
  position: absolute;
  left: 0;
  z-index: 2;
}

.topRightMediaButton {
  position: absolute;
  right: 0;
  z-index: 2;
}

.v-application .transparentBlackClose.topRightCloseBox {
  background-color: #00000042 !important;
  border-color: #00000042 !important;
}

.topRightCloseBox {
  position: absolute;
  right: 0;
  z-index: 2;
}

.mapboxgl-popup-anchor-bottom {
  margin-bottom: 30px;
}

.mapboxgl-popup-content {
  box-shadow: none;
}
.galPopup,
.galPopup .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}
#vue-popup-content {
  min-height: 550px;
}
.galPopup {
  min-height: 270px;
  height: auto;
}
.galPopup .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
  width: 285px;
  max-width: 95vw;
}

.titleBox {
  xmargin-top: -6px;
  xmargin-bottom: -6px;
}

.galPopup .mapboxgl-popup-content > div {
  zoom: 85%;
}

.mvPopUpCard button.v-btn.moreInfoButton {
  height: 26px !important;
  border: 1px solid #ffffff !important;
}

@media only screen and (max-width: 1263px) {
  .galPopup .mapboxgl-popup-content > div {
    zoom: 72%;
  }
}
@media only screen and (max-height: 800px) {
  .mvPopUpCard button.v-btn.moreInfoButton {
    height: 26px !important;
  }

  .galPopup .mapboxgl-popup-content > div {
    zoom: 72%;
  }
}

@media only screen and (max-width: 650px) {
  .galPopup .mapboxgl-popup-content > div {
    zoom: 72%;
  }
}

@media only screen and (max-height: 700px) {
  .galPopup .mapboxgl-popup-content > div {
    zoom: 72%;
  }
}
</style>
