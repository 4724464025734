//import _ from "lodash";

const opacityFull = 0.85;
const opacityHidden = 0.85;

const hideClusteredLayer = false;
/*
const getZoomScaleStops = function(factor) {
  return [
    [13, factor * 0],
    [14, factor * 0.01],
    [15, factor * 0.05],
    [17, factor * 0.3],
    [18.5, factor * 0.6],
    [20, factor * 1],
    [21.5, factor * 1.5]
  ];
};
*/
export default new (class {
  async createSignageLayer({
    parentThis,
    map,
    layerIdName,
    geoJson,
    appSettings,
    markerSections
  }) {
    //const layerIdName = "signage";
    //const pinScale = 1; //;2;

    let {
      hasPopup,
      clusterOptions,
      scaling,
      haveToolTip,
      hoverState,
      clusterSetup,
      filter,
      styles,
      showLabels,
      labelField
    } = appSettings.layerDefaults[layerIdName];

    let layerSitUnder = appSettings?.layersUnder[layerIdName];

    clusterSetup = { ...clusterSetup };
    if (markerSections) {
      let clusterProperties = {};
      for (const sectionKey in markerSections) {
        /*
        'clusterProperties': {
              // keep separate counts for each magnitude category in a cluster
              'points-of-interest': ['+', ['case', ["in",   ['get', 'section_slug'],"points-of-interest"], 1, 0]],
              'wadawurrung-stories': ['+', ['case', ["in",   ['get', 'section_slug'],"wadawurrung-stories"], 1, 0]],
              'food-and-shopping': ['+', ['case', ["in",   ['get', 'section_slug'],"food-and-shopping"], 1, 0]],
          }
        */
        clusterProperties[sectionKey] = [
          "+",
          ["case", ["in", ["get", "section_slug"], sectionKey], 1, 0]
        ];
      }
      clusterSetup.clusterProperties = clusterProperties;

      clusterSetup.clusterProperties.marker_indexs = [
        "concat",
        ["concat", ["get", "index"], ","]
      ];

      clusterSetup.clusterProperties.marker_point_name = [
        "concat",
        ["concat", ["get", "Point_Name"], ","]
      ];

      clusterSetup.clusterProperties.icon_ids = [
        "concat",
        ["concat", ["get", "icon_id"], ","]
      ];
    }

    if (geoJson?.data) {
      parentThis.dataSouceIds[layerIdName] = layerIdName;
      parentThis.filterableDataSouceIds[layerIdName] = layerIdName;

      map.addSource(layerIdName, {
        type: "geojson",
        data: geoJson.data,

        ...clusterSetup
      });
    }

    let layerId_clustered = `${layerIdName}_clustered_layer`;
    let layerId_clustered_lables = `${layerIdName}_clustered_lables`;
    let layerId_unclustered = `${layerIdName}_unclustered_layer`;
    let layerId_unclustered_lables = `${layerIdName}_unclustered_lables`;

    if (hideClusteredLayer !== true) {
      parentThis.pointLayerIds[layerId_unclustered] = layerId_unclustered;

      if (hoverState) {
        parentThis.hoverStatePointLayerIds[
          layerId_unclustered
        ] = layerId_unclustered;
      }

      if (haveToolTip) {
        parentThis.tooltipPointLayerIds[layerId_clustered] = layerId_clustered;

        parentThis.mobileClickTooltipPointLayerIds[
          layerId_clustered
        ] = layerId_clustered;
      }

      if (clusterOptions.useComboIcon === true) {
        parentThis.comboIconDataSoucesIds[layerIdName] = layerIdName;
      }

      if (hoverState && clusterOptions.useCircle) {
        parentThis.hoverStatePointLayerIds[
          layerId_clustered
        ] = layerId_clustered;
      }

      if (hasPopup != true) {
        parentThis.clusterLayersWithNoClick[
          layerId_clustered
        ] = layerId_clustered;
      }
      map.addLayer(
        {
          id: layerId_clustered,
          type: "circle",
          source: layerIdName,
          //    filter: ['has', 'point_count'],
          filter: filter.clustered_layer,
          minzoom: scaling.minZoom,

          paint: {
            ...styles?.clustered_paint,
            "circle-opacity": clusterOptions.useCircle
              ? {
                  stops: [
                    [scaling.clusterOpacityMinZoom, 0],
                    [scaling.clusterOpacityMaxZoom, 1]
                  ]
                }
              : 0.01,
            "circle-radius": 13
          }
        },
        layerSitUnder
      );

      //lables
      if (clusterOptions.useCircle) {
        parentThis.pointLayerIds[
          layerId_clustered_lables
        ] = layerId_clustered_lables;
        if (hoverState) {
          parentThis.hoverStatePointLayerIds[
            layerId_clustered_lables
          ] = layerId_clustered_lables;
        }
        if (haveToolTip) {
          parentThis.tooltipPointLayerIds[
            layerId_clustered_lables
          ] = layerId_clustered_lables;

          parentThis.mobileClickTooltipPointLayerIds[
            layerId_clustered_lables
          ] = layerId_clustered_lables;
        }
        map.addLayer(
          {
            id: layerId_clustered_lables,
            type: "symbol",
            source: layerIdName,
            minzoom: scaling.minZoom,
            filter: filter.clustered_layer,

            paint: {
              "text-color": appSettings.styles.markerText,
              "text-opacity": {
                stops: [
                  [scaling.clusterOpacityMinZoom, 0],
                  [scaling.clusterOpacityMaxZoom, 1]
                ]
              }
            },
            layout: {
              "icon-anchor": "bottom",
              "text-allow-overlap": true,
              "text-ignore-placement": true,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 15,
              "text-field": "+",
              "text-anchor": "center"
            }
          },
          layerSitUnder
        );
      }
    }

    let textField = showLabels
      ? {
          "text-field": [
            "get",
            labelField ? labelField : "Site_Marker_Number_Label"
          ]
        }
      : {};

    parentThis.pointLayerIds[layerId_unclustered] = layerId_unclustered;

    if (hoverState) {
      parentThis.hoverStatePointLayerIds[
        layerId_unclustered
      ] = layerId_unclustered;
    }
    if (haveToolTip) {
      parentThis.tooltipPointLayerIds[
        layerId_unclustered
      ] = layerId_unclustered;

      parentThis.mobileClickTooltipPointLayerIds[
        layerId_unclustered
      ] = layerId_unclustered;
    }

    map.addLayer(
      {
        id: layerId_unclustered,
        type: "symbol",
        source: layerIdName,

        minzoom: scaling.minZoom,
        filter: filter.unclustered_layer,

        paint: {
          "icon-opacity": {
            stops: [
              [scaling.opacityMinZoom, opacityHidden],
              [scaling.opacityMaxZoom, opacityFull]
            ]
          }
        },
        layout: {
          "icon-anchor": "bottom",
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-image": ["get", "icon_id"],
          "icon-size": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            17,
            0.1,
            18,
            0.25,
            20,
            0.5
          ]

          /*
        


          "icon-size": [
            "interpolate",
            ["exponential", 1],
            ["zoom"],
            15.5,
            [
              "match",
              ["get", "custom_icon_style"],
              "tall",
              scaling.unclusterScale * 0.15,
              scaling.unclusterScale * 0.1
            ],

            17.5,
            [
              "match",
              ["get", "custom_icon_style"],
              "tall",
              scaling.unclusterScale * 0.75,
              scaling.unclusterScale * 0.5
            ],
            19.5,
            [
              "match",
              ["get", "custom_icon_style"],
              "tall",
              scaling.unclusterScale * 1.5,
              scaling.unclusterScale * 1
            ]
          ]








["interpolate", ["linear"], ["zoom"],16, 1,18, 5]

         [
          "match",
          ["get", "section_slug"],
          "keepertalks",
          ["literal", 1],
          ["literal", 1] //other
        ]



        
          
        
        
        {
          stops: [
            [13, scaling.unclusterScale * 0],
            [14, scaling.unclusterScale * 0.01],
            [15, scaling.unclusterScale * 0.05],
            [18.5, scaling.unclusterScale * 0.6],
            [20, scaling.unclusterScale * 1],
            [21.5, scaling.unclusterScale * 1.5]
          ]
        }
*/

          /*
[
            "match",
            ["get", "theme"],
            "hhw",
            ["literal", [0, 1.3-2]],
            ["literal", [0, 0.4-2]] //other
          ]
*/

          //{stops: getZoomScaleStops(1),}

          /*{[
            [5, 0.03 * scaling.unclusterScale],
            [10, 0.07 * scaling.unclusterScale],
            [12, 0.1 * scaling.unclusterScale],
            [13, 0.2 * scaling.unclusterScale],

            [14.5, 0.5 * scaling.unclusterScale],
            [17.3, 1 * scaling.unclusterScale],
            [19, 1.3 * scaling.unclusterScale]
          ] }*/

          /*{
          stops: getZoomScaleStops(0.08 / pinScale)
        }*/
        }
      },
      layerSitUnder
    );

    if (showLabels) {
      parentThis.pointLayerIds[
        layerId_unclustered_lables
      ] = layerId_unclustered_lables;

      map.addLayer(
        {
          id: layerId_unclustered_lables,
          type: "symbol",
          source: layerIdName,
          minzoom: scaling.minZoom,
          filter: filter.unclustered_layer,

          paint: {
            "text-color": appSettings.styles.markerText,
            "text-opacity": {
              stops: [
                [scaling.opacityMinZoom, 0],
                [scaling.opacityMaxZoom, 1]
              ]
            }
          },
          layout: {
            "icon-anchor": "bottom",
            "text-allow-overlap": false,
            "text-ignore-placement": false,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": {
              stops: [
                [13 - 4, 5],
                [18 - 4, 10],
                [19 - 4, 15],
                [21 - 4, 30]
              ]
              /*
            [13, 0.2 * scaling.unclusterScale],
            [18, 0.2 * scaling.unclusterScale],
            //[17, 0.3 * scaling.unclusterScale],
            [20, 1 * scaling.unclusterScale]
*/

              //getZoomScaleStops(2)
            },
            ...textField,
            "text-anchor": "bottom",
            "text-offset": [0, 0.4]
          }
        },
        layerSitUnder
      );
    }
  }
})();
