<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.mdAndDown
          ? 500
          : 900
      "
    >
      <template v-slot:activator="{ on, attrs }" v-show="showButton">
        <span
          v-bind="attrs"
          v-on="on"
          class=" "
          v-show="showButton"
          @click="scrollToSiteDelay()"
        >
          Welcome
        </span>
      </template>

      <v-card class="dialog px-3">
        <div class="d-flex flex-row  justify-space-between " style="width:100%">
          <div></div>
          <v-card-title
            v-if="true"
            :style="{
              background: 'white'
            }"
            class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
          >
            Welcome to {{ siteObject.title }}
          </v-card-title>

          <v-btn
            @click="dialog = false"
            style="margin-right: -10px;margin-top: 4px;"
            icon
            color="black"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row>
          <v-col
            v-if="false"
            cols="12"
            lg="12"
            v-show="!$vuetify.breakpoint.mdAndDown"
            class=""
          >
            <intoVideo></intoVideo>
          </v-col>
          <v-col
            cols="12"
            lg="12"
            id="AboutContentWrapper"
            class="contentWrapper"
            @scroll="onScroll"
          >
            Welcome to Lone Pine Koala Sanctuary, the world's first and largest
            koala sanctuary. We are lucky enough to be the caretakers of over
            100 koalas and 75 species of native Australian animals. Many wild
            animals also call Lone Pine home, such as birds, snakes, lizards,
            insects, and possums. As a guest to the sanctuary, it is important
            to remember that you are entering our animals' home. Please treat
            them with dignity and respect.<br />
            <br />
            Now let's go! It's time to discover your sanctuary...
            <br />
            <br />
            <WelcomeDialog_gen :dialog="dialog" :isReady="isReady" />
          </v-col>
        </v-row>

        <v-card-actions class="px-0 mt-3">
          <v-btn depressed color="primary" @click="nav_faq()" v-show="false">
            FAQ
          </v-btn>
          <v-spacer v-show="false"></v-spacer>

          <transition name="fade" mode="out-in">
            <v-btn
              depressed
              color="primary"
              v-show="$vuetify.breakpoint.mdAndDown && !atTop"
              class="v-fade"
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>
          <v-spacer></v-spacer>

          <v-btn depressed color="primary" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import { mapState } from "vuex";
import intoVideo from "./intoVideoOld.vue";

import WelcomeDialog_gen from "./WelcomeDialog_gen.vue";
// import mvWpContentBlock from "../../ui/mvWpContent/mvWpContentBlock.vue";

export default {
  name: "WelcomeDialog",
  components: {
    intoVideo,
    WelcomeDialog_gen
    // mvWpContentBlock
  },
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Welcome to Melbourne Zoo Maptivate"
    };
  },
  computed: {
    ...mapState({
      siteId: state => state.global.siteId,
      siteObject: state => state.global.siteObject,
      appSettings: state => state.global.appSettings,
      imageLog: state => state.markers.imageLog
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", scrollTo => {
      console.log("loadAboutDialog");
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  props: {
    isReady: Boolean,
    showButton: Boolean
  },
  methods: {
    nav_faq() {
      eventBus.$emit("loadHelpDialog");
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    updateDontShowCheckbox() {
      localStorage.setItem("dontShowWelcome", this.dontShowAgain);
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `AboutContentWrapper`;

      if (newValue) {
        element = `aboutHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById("AboutContentWrapper");
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  },
  mounted() {
    let firstVisitToday = false;

    const lastVisit = localStorage.getItem("lastVisitDate");
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    if (lastVisit !== today) {
      // Run your first-visit event here

      firstVisitToday = true;
      // Update the last visit date
    }

    localStorage.setItem("lastVisitDate", today);
    if (firstVisitToday) {
      this.dialog = true;
      setTimeout(() => {
        eventBus.$emit("loadAocDialog");
      }, 500);
    }
  }
};
</script>

<style>
.imageWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper,
  .introVideo {
    max-height: calc(100vh - 240px);
  }

  .imageWrapper {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }

  .isApple .contentWrapper,
  .isApple .introVideo {
    max-height: calc(100vh - 260px);
  }

  .isApple .imageWrapper {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
}

.v-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>
