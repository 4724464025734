<template>
  <div>
    <v-toolbar color="menuBarColor">
      <v-tabs
        v-model="tab"
        color="primary darken-4  "
        :dark="appSettings.mainTabDark === true"
        class="mainNavSideBar"
      >
        <v-tabs-slider color="tabSliderColor"></v-tabs-slider>

        <v-tab
          :class="[
            'headingText',
            'mainNavSideBarTab',
            appSettings.mainTabDark ? 'ligherText' : 'darkerText'
          ]"
          v-for="tabItem in tabList"
          :key="tabItem"
          :disabled="
            tabItem == 'More Info' && !sidebarSite && sidebarSite !== 0
              ? true
              : false
          "
        >
          {{ tabItem }}
        </v-tab>
      </v-tabs>

      <v-btn
        :dark="appSettings.mainTabDark === true"
        v-show="!$vuetify.breakpoint.smAndDown"
        class="mvHover"
        large
        icon
        tile
        @click="resetMap()"
        title="Reset Interface"
      >
        <v-icon medium color="">mdi-restart</v-icon>
      </v-btn>
      <v-btn
        v-show="$vuetify.breakpoint.mdAndDown"
        :dark="appSettings.mainTabDark === true"
        class="mvHover"
        large
        icon
        tile
        @click="closeMenu"
        title="Close Menu"
      >
        <v-icon medium>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-tabs-items v-model="tab" class="tabcontent">
      <v-tab-item>
        <div class="siteListWrapperOld">
          <v-card flat>
            <SiteList :isOpen="tab === 0" :parentDrawer="parentDrawer" />
          </v-card>
        </div>
      </v-tab-item>
      <v-tab-item>
        <sidebarEventsList />
      </v-tab-item>
      <v-tab-item>
        <SidebarSiteInfo />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { eventBus } from "../../main";

import { mapState } from "vuex";
import SiteList from "./SiteList.vue";
import SidebarSiteInfo from "../moreInfo/SidebarSiteInfo.vue";

import sidebarEventsList from "./sidebarEventsList.vue";

export default {
  props: {
    isOpen: Boolean,
    parentDrawer: Boolean
  },
  components: {
    SiteList,
    sidebarEventsList,
    SidebarSiteInfo
  },
  name: "SideBar",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      sidebarSite: state => state.navigation.sidebarSite
    }),
    sites() {
      return this.markerList.data.features;
    }
  },
  data() {
    return {
      tab: 0,
      tabList: ["Things to See", "Things to Do", "More Info"]
    };
  },
  methods: {
    resetMap() {
      eventBus.$emit("nav_map_reset");
    },
    closeMenu() {
      eventBus.$emit("nav_layout_closeMenu");
    }
  },
  created() {
    eventBus.$on("nav_sideBar_openSiteList", () => {
      this.tab = 0;
    });

    eventBus.$on("nav_sideBar_openMoreInfo", () => {
      this.tab = 2;
    });
  },

  watch: {}
};
</script>
<style>
.tabcontent {
  max-height: calc(100vh - 116px);
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.siteListWrapperOld {
  max-height: calc(100vh - 118px);
  overflow: hidden;
}
.siteListWrapper {
  max-height: calc(100vh - 118px - 48px);
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 1263px) {
  .tabcontent {
    max-height: calc(100vh - 80px);
  }
  .siteListWrapper {
    max-height: calc(100vh - 82px);
  }
}
.mainNavSideBar .v-slide-group__prev,
.mainNavSideBar .v-slide-group__next {
  display: none !important;
}

.mainNavSideBar .mainNavSideBarTab.v-tab {
  min-width: 80px;
  max-width: 116px;
}

.currentCountryNotEnglish .mainNavSideBar .mainNavSideBarTab.v-tab {
  min-width: 80px;
  max-width: 130px;
  line-height: 1;
  overflow: hidden;
}
.mainNavSideBar .mainNavSideBarTab.v-tab.v-tab--active.ligherText {
  color: white;
}

.mainNavSideBar .mainNavSideBarTab.v-tab.v-tab--active.darkerText {
  color: black;
}

@media only screen and (max-width: 600px) {
  .mainNavSideBar .mainNavSideBarTab.v-tab {
    width: 80px;
    line-height: 1;
    min-width: auto;

    padding: 0 8px;
  }

  .mainNavSideBar .v-slide-group__prev,
  .mainNavSideBar .v-slide-group__next {
    display: none !important;
  }
}

.mainNavSideBar
  .v-slide-group__content.v-tabs-bar__content
  .headingText.mainNavSideBarTab.v-tab:nth-child(2) {
  min-width: 95px;
}
</style>
