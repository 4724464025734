import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// const siteSwitcherService = require("./../service/siteSwitcherService.js");

// const themeLight = siteSwitcherService.getSiteSetup().theme;

Vue.use(Vuetify, {
  iconfont: "md"
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: "#ff0000",",
        ////accent: "#476B63",
        //// error: "#546B66"
        //"primary--text": "#ff0000"

        primary: "#5B7E74", // CHANGED
        secondary: "#1A1919", // CHANGED
        accent: "#fff", //link text
        error: "#1f3531", //item se
        ////secondary: "#394246lected
        info: "#51702d",
        success: "#061a35",
        //warning: "#00F"
        warning: "#fcfbf1", //marker text

        header: "#a3bba0",
        tabs: "#96a992",

        mediaButtonColor: "#5B7E74", // CHANGED

        filterLegendButtonColor: "#5B7E74", // CHANGED

        dialogActionButtonColor: "#5B7E74", // CHANGED
        dialogToobarColor: "#5B7E74", // CHANGED
        logoTextColor: "#e9e3cd",
        filterButtonColor: "#E7EAED", // CHANGED
        mainMenuBarColor: "#5B7E74", // CHANGED
        menuBarColor: "#75a093", // CHANGED

        langButtonColor: "#75a093", // CHANGED
        tabSliderColor: "#51702d",

        logoColor: "#fff"
      }
    }
  }
});
