<template>
  <v-app
    :class="{
      currentCountryNotEnglish: currentCountryNotEnglish,
      'my-app': true,
      isApple: isIOS
    }"
  >
    <Layout v-if="ready" />
    <welcomeDialog
      :isReady="ready"
      :showButton="false"
      v-if="true"
      class="mr-8"
    />
    <acknowledgementDialog
      :isReady="ready"
      :showButton="false"
      v-if="true"
      class="mr-8"
    />
    <ShareDialog />
  </v-app>
</template>

<script>
import Layout from "./Layout.vue";
import { mapState } from "vuex";

import welcomeDialog from "./components/dialogs/welcome/welcomeDialog.vue";
import acknowledgementDialog from "./components/dialogs/acknowledgement/AcknowledgementDialog.vue";
import ShareDialog from "./components/dialogs/ShareDialog.vue";

export default {
  name: "App",
  async mounted() {
    this.$store.dispatch("wpData_initData", { vm: this });
  },
  components: {
    Layout,
    ShareDialog,
    welcomeDialog,
    acknowledgementDialog
  },

  data: () => ({
    ready: false
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      wpdata: state => state.wpdata.wpdata,
      currentCountryNotEnglish: state =>
        state.translator.currentCountryNotEnglish
    }),
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    wpdata: function(newValue) {
      if (newValue?.categoryOrder.length) {
        this.$store.dispatch("global_updateMaptivateSetupObject", newValue);
        this.$store.dispatch("markers_updateData", newValue);
        this.$store.dispatch("filters_updateData", newValue);

        this.ready = true;
      }
    }
  },

  created() {
    window.mvAnalyticEvent = function(category, action, label, value) {
      //console.log({action,category, label} );
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: !isNaN(value) ? value : 1
      });
      //window.gtag("event", "t_" + action + "_" + label);
    };

    document.ondblclick = function(e) {
      e.preventDefault();
    };

    document.addEventListener("gesturestart", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gestureend", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });
    /*
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    window.addEventListener("orientationchange", documentHeight);
    screen.orientation.addEventListener("change", documentHeight);

    documentHeight();
    
    */
  }
};
</script>
<style lang="scss">
:root {
  --doc-height: 100%;
}
body {
  //touch-action: manipulation;
  touch-action: pan-y, pan-x;
}

.mapboxgl-ctrl-bottom-right
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
  right: 68px;
}
</style>
