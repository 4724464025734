//import _ from "lodash";
import { mapState } from "vuex";
import { eventBus } from "../../../main";

export default {
  data: () => ({
    trackFitBounds: false,

    minHeight: 400,
    maxHeight: 550
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      //trailSelectOptions: state => state.filters.trailSelectOptions,
      //formatTrailLookup: state => state.filters.formatTrailLookup,
      sidebarSite: state => state.navigation.sidebarSite,
      activeQueryStringMarkerId: state =>
        state.navigation.activeQueryStringMarkerId
    }),

    hasSiteSelected() {
      return (
        this.sidebarSite ||
        this.sidebarSite == 0 ||
        this.activeQueryStringMarkerId
      );
    },

    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    updatePaddingZoomForRemotePopup(coordinates, flyTo, props, where) {
      
      //split this up into 3 functions, zoom to pin, zoom to area, and a wrapper function;
      if ((props && props?.hide) || (!coordinates[0] && !coordinates[1])) {
        //do nothing!!
      } else {
        if (where) {
          //do nothing
        }

        if (window.outerHeight < 700 || this.$vuetify.breakpoint.mobile) {
          //setting padding so info window canload up ;
          //this.map.setPadding({ top: (window.outerHeight - 80) / 2 });
        } else {
          //this.map.setPadding({ top: 0 });
        }

        if (flyTo) {
          let options = {
            //padding: 10, // Padding in pixels
            offset: [0, this.getOffset(props)],
            speed: this.useCluster ? 0.5 : 1,
            center: coordinates,
            zoom:
              this.map.getZoom() < this.appSettings.zoomToOnSelect
                ? this.appSettings.zoomToOnSelect + 0.5
                : this.map.getZoom()
          };

          this.map.flyTo(options);
        }
      }
    },

    mvGetScreenHightObj() {
      let height = this.$refs.mapWrapper.clientHeight;
      let headerBottom;

      let footerTop;
      try {
        //this is needed because of mobile phones,
        const header = document.querySelector(".mainMvToolBar");
        const footer = document.querySelector(".mainMvFooter");

        headerBottom = header.getBoundingClientRect().bottom;

        footerTop = footer.getBoundingClientRect().top;

        if (header && footer) {
          height = footerTop - headerBottom;
        } else {
          throw { error: "error" };
        }
      } catch (error) {
        //
      }

      return { height, headerBottom, footerTop };
    },
    getOffset(props) {
      let infoWinHeight = props?.media?.length
        ? this.maxHeight
        : this.minHeight;

      let offsetY = 0;

      try {
        let mapHeight = this.mvGetScreenHightObj().height;

        if (mapHeight / 2 < infoWinHeight) {
          offsetY = infoWinHeight / 2;
        }

        if (mapHeight / 2 < offsetY + 40) {
          offsetY = mapHeight / 2;
          offsetY = offsetY - 80;
        }
      } catch (e) {
        //do nothing;
      }

      return offsetY;
    },

    moveEndCheckPopupPositionGen() {
      
      try {
        const popupContent = document.querySelector(".mapboxgl-popup-content");
      

        if (popupContent) {
          //const menubarHeight = 64 + 80;

          let popupTopDistanceToTop = popupContent.getBoundingClientRect().top; //80 for extra menu;

          let popupBottomDistanceToTop = popupContent.getBoundingClientRect()
            .bottom;

          let {
            height: mapHeight,
            headerBottom,
            footerTop
          } = this.mvGetScreenHightObj();

          let distFromTop = popupTopDistanceToTop - headerBottom - 60;
          //let distFromTopToBottom = popupBottomDistanceToTop - headerBottom - 60;
          let distFromBottom = footerTop - popupBottomDistanceToTop;

          let absoluteValueOfTop = -1;
          try {
            absoluteValueOfTop = Math.abs(distFromTop);
          } catch (error) {
            //do nothing;
            
          }

   

          let absoluteValueOfBottom = -1;
          try {
            absoluteValueOfBottom = Math.abs(distFromBottom);
          } catch (error) {
            //do nothing;
            
          }
          let offset = false;

          let message = "";

          if (
            absoluteValueOfTop > mapHeight * 2 ||
            absoluteValueOfBottom > mapHeight * 2
          ) {

            message = "Too far out";
          } else if (distFromTop < 0) {

            message = `Above Top, Move down`;

            offset = distFromTop * -1 + 10;
          } else if (distFromBottom < 0) {

            offset = distFromBottom - 20; //-20 so not sitting on bottom!!;


            if (popupTopDistanceToTop + offset < headerBottom + 40) {
              let offsetOffeset =
                headerBottom - (popupTopDistanceToTop + offset);

              offset = offset + offsetOffeset + 60;
            }

            //if((popupTopDistanceToTop+offset))

            message = `Below bottom, Move up!!`;
          } else {

            message = `is good!!!`;
          }

          let output = JSON.stringify({
            o: Math.round(offset),
            t: Math.round(distFromTop),
            b: Math.round(distFromBottom),
            h: Math.round(mapHeight),
            pt: Math.round(popupTopDistanceToTop),
            pb: Math.round(popupBottomDistanceToTop),

            hb: Math.round(headerBottom),
            ft: Math.round(footerTop),
            message
          });

          if (output) {
            //
          }
          if (!isNaN(offset) && offset !== false) {
            if (this.isIOS) {
              //do nothing;
            }

            //else {
            const coordinates = this.map.getCenter();

            if (offset > 0) {
              //offset = offset + 40;
            } else {
              //offset = offset - 40;
            }

            let options = {
              offset: [0, offset],
              center: coordinates
            };

            this.map.flyTo(options);
            //}
          }
        }
      } catch (e) {
        //console.error({ e });
        //do nothing;
      }
    },

    goToPointByPointName(pointName) {
      
      let [point] = this.sites.filter(row => {
        return row.properties.Point_Name == pointName;
      });

      if (point && point.properties) {
        this.loadMarkerByIdFromMenu(point.properties.index);
      }
    },

    flyToPoint(layer, site) {
      this.updatePaddingZoomForRemotePopup(
        site?.geometry?.coordinates,
        true,
        site?.properties,
        "D"
      );

      if (layer == "markerList") {
        this.createPopupFromIndex(site?.properties?.index);
      }
    }
  },
  created() {
    eventBus.$on("flyToPoint", (layer, site) => {
      this.flyToPoint(layer, site);
    });

    eventBus.$on("nav_map_flyToLongLat", coordinates => {
      this.clearAllPopups();

      this.updatePaddingZoomForRemotePopup(coordinates, true, {}, "B");
      if (this.$vuetify.breakpoint.mdAndDown) {
        eventBus.$emit("nav_layout_closeMenu");
      }
    });
    eventBus.$on("nav_map_flyToFeature", feature => {
      this.clearAllPopupsBasic();

      let properties = feature?.properties;
      let coordinates = feature?.geometry?.coordinates;

      if (coordinates) {
        this.updatePaddingZoomForRemotePopup(
          coordinates,
          true,
          properties,
          "C"
        );
      }

      if (this.$vuetify.breakpoint.smAndDown) {
        eventBus.$emit("nav_layout_closeMenu");
      }

      let openToolTip = () => {
        let event = {
          features: [feature]
        };

        this.createTooltip(event);
      };

      setTimeout(() => {
        openToolTip();
      }, 1000);
    });

    eventBus.$on("nav_map_flyToOpenPopUpByName", pointName => {
      this.goToPointByPointName(pointName);
    });
    eventBus.$on("nav_map_flyToOpenPopUp_Event", index => {
      
      this.createPopupFromIndex(index);
      if (this.$vuetify.breakpoint.mdAndDown) {
        eventBus.$emit("nav_layout_closeMenu");
      }
    });
    eventBus.$on("nav_map_flyToOpenPopUp", index => {
      
      this.createPopupFromIndex(index);
      if (this.$vuetify.breakpoint.mdAndDown) {
        eventBus.$emit("nav_layout_closeMenu");
      }
    });
  },
  destroyed() {},
  mounted() {},

  watch: {}
};
